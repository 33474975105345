.ListItem:hover {
  color: #285780;
  font-weight: 600;
  font-size: 2rem !important;
}
.ListItem:hover .ListItemIcon {
  color: #285780;
  font-weight: 600;
  font-size: 1.6rem !important;
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
.YourHighlightClass {
  color: #275780 !important;
  font-weight: 600;
  background: none !important;
 
  /* font-size: 2rem !important; */
}

.search-results:hover {
background: #f5f5f5 !important;
}



@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

