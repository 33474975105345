.markomplete {
  padding: 0.45em 1rem;
  /* font-size: 1.3rem !important; */
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
  cursor: pointer;
  background-color: #f36045 !important;
  color: #fff !important;
  border: 4px solid #fff !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.docmarkcombtn {
  top: 28px !important;
  right: 60px !important;
  left: unset !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.greenborder {
  border: 2px solid green;
  border-radius: 5px;
}
.redboreder {
  border: 2px solid red;
  border-radius: 5px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
