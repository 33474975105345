.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-dots {
  bottom: 30px;
}
.slick-dots li button:before {
  font-size: 13px;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
}
