.container-1 {
  width: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #777777;
  padding: 5% 0;
  max-height: 300px;
  cursor: pointer;

  background-color: #ffffff;
}
.container-1 .img-c {
  display: flex;
  justify-content: center;
}
.container-1 .org-container-1 {
  margin: 12px 0;
  line-height: 1;
}
.org-container-1 .org {
  font-size: 15px;
  font-weight: 900;
}
.container-1 .name {
  margin: 0 0 5px 0;
}
.container-1 .complete {
  margin: 0 0 5px 0;
  font-size: 9px;
}
.title-container-1 {
  margin: 0 0 5px 0;
  line-height: 1;
}
.container-1 .org-container-1 .org {
  color: #f36045;
}
.container-1 .org-container-1 p {
  font-size: 9px;
}
.date-1 {
  margin: 7px 0;
  font-size: 9px;
}

.title-1 {
  font-size: 12px;
}
.name-1 {
  font-size: 12px;
}
