.bgImage {
  background-repeat: no-repeat;
  background-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
}
.darkBgColor {
  background-color: #f36045 !important;
  fill: #7c7f91 !important;
}
.grayBgColor {
  background-color: #7c7f91 !important;
  fill: #7c7f91 !important;
}
.videoIconDark {
  background-color: red;
  /* //   background-image: url('../../assets/media/objects/video.png'); */
  -webkit-mask-image: url("../../../assets/media/objects/videosvg.svg");
  mask-image: url("../../../assets/media/objects/videosvg.svg");
}
.audioIconDark {
  -webkit-mask-image: url("../../../assets/media/objects/audiosvg.svg");
  mask-image: url("../../../assets/media/objects/audiosvg.svg");
  /* //background-image: url('../../assets/media/objects/audio.png'); */
}
.youtubeicon {
  -webkit-mask-image: url("../../../assets/media/objects/youtubesvg.svg");
  mask-image: url("../../../assets/media/objects/youtubesvg.svg");
}
.vimeoicon {
  -webkit-mask-image: url("../../../assets/media/objects/vimeosvg.svg");
  mask-image: url("../../../assets/media/objects/vimeosvg.svg");
  /* //background-image: url('../../assets/media/objects/audio.png'); */
}
.htmlIconDark {
  -webkit-mask-image: url("../../../assets/media/objects/htmlsvg.svg");
  mask-image: url("../../../assets/media/objects/htmlsvg.svg");
}
.pdfIconDark {
  -webkit-mask-image: url("../../../assets/media/objects/pdfsvg.svg");
  mask-image: url("../../../assets/media/objects/pdfsvg.svg");
}

.interIconDark {
  /* //   background-image: url('../../assets/media/objects/inter.png'); */
  -webkit-mask-image: url("../../../assets/media/objects/swiperblack.svg");
  mask-image: url("../../../assets/media/objects/swiperblack.svg");
}
.scormIconDark {
  /* //   background-image: url('../../assets/media/objects/scorm.png'); */
  -webkit-mask-image: url("../../../assets/media/objects/scormsvg.svg");
  mask-image: url("../../../assets/media/objects/scormsvg.svg");
}
.quizIconDark {
  /* //   background-image: url('../../assets/media/objects/quiz.png'); */
  -webkit-mask-image: url("../../../assets/media/objects/quizsvg.svg");
  mask-image: url("../../../assets/media/objects/quizsvg.svg");
}
.timer {
  /* //background-image: url('../../assets/media/timer.png'); */
  -webkit-mask-image: url("../../../assets/media/timer.svg");
  mask-image: url("../../../assets/media/timer.svg");
  background-color: gray;
}
